import React, { useState } from 'react';
import Style from '../assets/css/sort.module.css';

const Sort = () => {
    const [algorithm, setAlgorithm] = useState('bubble');
    const [dataSize, setDataSize] = useState(10);
    const [originalData, setOriginalData] = useState([]);
    const [sortingData, setSortingData] = useState([]);
    const [isSorting, setIsSorting] = useState(false);
    const [speed, setSpeed] = useState(50); // Velocidad inicial (50%)

    // Generar datos aleatorios
    const generateRandomData = (size) => {
        const data = [];
        for (let i = 0; i < size; i++) {
            data.push(Math.floor(Math.random() * 100) + 1);
        }
        return data;
    };

    // Función para calcular el tiempo de espera en función de la velocidad
    const getDelay = () => {
        const minDelay = 10; // Mínimo retraso (10%)
        const maxDelay = 300; // Máximo retraso (100%)
        return maxDelay - ((speed / 100) * (maxDelay - minDelay));
    };

    // Bubble Sort con visualización paso a paso
    const bubbleSortStepByStep = async (arr, updateFunction) => {
        let n = arr.length;
        for (let i = 0; i < n - 1; i++) {
            for (let j = 0; j < n - i - 1; j++) {
                if (arr[j] > arr[j + 1]) {
                    // Intercambiar elementos
                    let temp = arr[j];
                    arr[j] = arr[j + 1];
                    arr[j + 1] = temp;

                    // Actualizar el estado y esperar un momento para visualizar
                    await updateFunction([...arr]);
                    await new Promise((resolve) => setTimeout(resolve, getDelay()));
                }
            }
        }
    };

    // QuickSort con visualización paso a paso
    const quickSortStepByStep = async (arr, updateFunction) => {
        if (arr.length <= 1) {
            return arr;
        }
        const pivot = arr[arr.length - 1];
        const left = [];
        const right = [];
        for (let i = 0; i < arr.length - 1; i++) {
            if (arr[i] < pivot) {
                left.push(arr[i]);
            } else {
                right.push(arr[i]);
            }
        }
        const sortedLeft = await quickSortStepByStep(left, updateFunction);
        const sortedRight = await quickSortStepByStep(right, updateFunction);

        const sortedArray = [...sortedLeft, pivot, ...sortedRight];
        await updateFunction(sortedArray); // Actualizar el estado
        await new Promise((resolve) => setTimeout(resolve, getDelay()));
        return sortedArray;
    };

    // Selection Sort con visualización paso a paso
    const selectionSortStepByStep = async (arr, updateFunction) => {
        let n = arr.length;
        for (let i = 0; i < n - 1; i++) {
            let minIndex = i;
            for (let j = i + 1; j < n; j++) {
                if (arr[j] < arr[minIndex]) {
                    minIndex = j;
                }
            }
            if (minIndex !== i) {
                // Intercambiar elementos
                let temp = arr[i];
                arr[i] = arr[minIndex];
                arr[minIndex] = temp;

                // Actualizar el estado y esperar un momento para visualizar
                await updateFunction([...arr]);
                await new Promise((resolve) => setTimeout(resolve, getDelay()));
            }
        }
    };

    // Insertion Sort con visualización paso a paso
    const insertionSortStepByStep = async (arr, updateFunction) => {
        let n = arr.length;
        for (let i = 1; i < n; i++) {
            let key = arr[i];
            let j = i - 1;
            while (j >= 0 && arr[j] > key) {
                arr[j + 1] = arr[j];
                j = j - 1;

                // Actualizar el estado y esperar un momento para visualizar
                await updateFunction([...arr]);
                await new Promise((resolve) => setTimeout(resolve, getDelay()));
            }
            arr[j + 1] = key;
        }
    };

    // Función para manejar el cambio en el algoritmo seleccionado
    const handleAlgorithmChange = (e) => {
        setAlgorithm(e.target.value);
    };

    // Función para manejar el cambio en el tamaño de los datos
    const handleDataSizeChange = (e) => {
        const size = parseInt(e.target.value, 10);
        setDataSize(size);
        const newData = generateRandomData(size);
        setOriginalData(newData);
        setSortingData([]); // Limpiar datos de ordenamiento
    };

    // Función para manejar el cambio en la velocidad
    const handleSpeedChange = (e) => {
        setSpeed(parseInt(e.target.value, 10));
    };

    // Función para iniciar el ordenamiento visual
    const handleSort = async () => {
        if (isSorting) return; // Evitar múltiples clics mientras se ordena
        setIsSorting(true);

        const dataToSort = [...originalData];
        setSortingData([...dataToSort]); // Iniciar con los datos originales

        if (algorithm === 'bubble') {
            await bubbleSortStepByStep(dataToSort, (updatedData) => {
                setSortingData([...updatedData]);
            });
        } else if (algorithm === 'quicksort') {
            await quickSortStepByStep(dataToSort, (updatedData) => {
                setSortingData([...updatedData]);
            });
        } else if (algorithm === 'selection') {
            await selectionSortStepByStep(dataToSort, (updatedData) => {
                setSortingData([...updatedData]);
            });
        } else if (algorithm === 'insertion') {
            await insertionSortStepByStep(dataToSort, (updatedData) => {
                setSortingData([...updatedData]);
            });
        }

        setIsSorting(false); // Finalizar el proceso de ordenamiento
    };

    // Función para renderizar las barras
    const renderBars = (data) => {
        return data.map((value, index) => (
            <div
                key={index}
                className={Style.bar}
                style={{ height: `${value}%`, width: `${100 / data.length}%` }}
            >
                <span className={Style.barLabel}>{value}</span>
            </div>
        ));
    };

    // Información sobre los algoritmos
    const algorithmInfo = {
        bubble: {
            name: "Bubble Sort",
            description: "Bubble Sort es un algoritmo simple que compara elementos adyacentes y los intercambia si están en el orden incorrecto. Este proceso se repite hasta que no se necesiten más intercambios.",
            timeComplexity: "O(n²)",
            spaceComplexity: "O(1)",
            bestCase: "O(n) (cuando la lista ya está ordenada)",
            worstCase: "O(n²)",
        },
        quicksort: {
            name: "QuickSort",
            description: "QuickSort es un algoritmo de ordenamiento eficiente que utiliza la técnica 'divide y vencerás'. Selecciona un pivote y divide la lista en dos sublistas: una con elementos menores al pivote y otra con elementos mayores.",
            timeComplexity: "O(n log n)",
            spaceComplexity: "O(log n)",
            bestCase: "O(n log n)",
            worstCase: "O(n²) (en el peor caso, cuando el pivote es el menor o mayor elemento)",
        },
        selection: {
            name: "Selection Sort",
            description: "Selection Sort selecciona el elemento más pequeño de la lista y lo coloca en su posición correcta. Este proceso se repite para el resto de la lista.",
            timeComplexity: "O(n²)",
            spaceComplexity: "O(1)",
            bestCase: "O(n²)",
            worstCase: "O(n²)",
        },
        insertion: {
            name: "Insertion Sort",
            description: "Insertion Sort construye la lista ordenada uno por uno, insertando cada elemento en su posición correcta. Es eficiente para listas pequeñas o casi ordenadas.",
            timeComplexity: "O(n²)",
            spaceComplexity: "O(1)",
            bestCase: "O(n) (cuando la lista ya está ordenada)",
            worstCase: "O(n²)",
        },
    };

    return (
        <div className={Style.containerSort}>
            <h1>Visualizador de Algoritmos de Ordenamiento</h1>
            
            <div className={Style.controls}>
                <label>
                    Selecciona un algoritmo:
                    <select value={algorithm} onChange={handleAlgorithmChange}>
                        <option value="bubble">Bubble Sort</option>
                        <option value="quicksort">QuickSort</option>
                        <option value="selection">Selection Sort</option>
                        <option value="insertion">Insertion Sort</option>
                    </select>
                </label>

                <label>
                    Cantidad de datos:
                    <input
                        type="range"
                        min="5"
                        max="50"
                        value={dataSize}
                        onChange={handleDataSizeChange}
                        className={Style.rangeInput}
                    />
                    {dataSize}
                </label>

                <label>
                    Velocidad:
                    <input
                        type="range"
                        min="1"
                        max="100"
                        value={speed}
                        onChange={handleSpeedChange}
                        className={Style.rangeInput}
                    />
                    {speed}%
                </label>

                <button onClick={handleSort} disabled={isSorting}>
                    {isSorting ? 'Ordenando...' : 'Ordenar'}
                </button>
            </div>

            <div className={Style.dataDisplay}>
                <h2>Datos Originales:</h2>
                <div className={Style.barContainer}>
                    {renderBars(originalData)}
                </div>

                <h2>Proceso de Ordenamiento:</h2>
                <div className={Style.barContainer}>
                    {renderBars(sortingData)}
                </div>
            </div>

            {/* Información del algoritmo */}
            <div className={Style.algorithmInfo}>
                <h2>Información del Algoritmo: {algorithmInfo[algorithm].name}</h2>
                <p><strong>Descripción:</strong> {algorithmInfo[algorithm].description}</p>
                <p><strong>Complejidad Temporal:</strong> {algorithmInfo[algorithm].timeComplexity}</p>
                <p><strong>Complejidad Espacial:</strong> {algorithmInfo[algorithm].spaceComplexity}</p>
                <p><strong>Mejor Caso:</strong> {algorithmInfo[algorithm].bestCase}</p>
                <p><strong>Peor Caso:</strong> {algorithmInfo[algorithm].worstCase}</p>
            </div>
        </div>
    );
};

export default Sort;