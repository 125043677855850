import styles from "../assets/css/control.module.css";
import { useState, useEffect } from "react";
import io from "socket.io-client";
import Swal from "sweetalert2";
import { FaBolt, FaIndustry, FaBroadcastTower } from "react-icons/fa";
import { TbCircleDashedLetterG } from "react-icons/tb";

const socket = io("https://web-socket-zfbl.onrender.com");

const Control = () => {
    const [switch1, setSwitch1] = useState(true);
    const [switch2, setSwitch2] = useState(false);
    const [estadoBoton, setEstadoBoton] = useState(false);

    useEffect(() => {
        socket.on("estadoActualizado", (estado) => {
            setEstadoBoton(estado);
            setSwitch1(estado);
            setSwitch2(!estado);
        });
    
        socket.emit("obtenerEstado");
    
        return () => {
            socket.off("estadoActualizado");
        };
    }, []);    

    const handleSwitch1 = () => {
        setSwitch1(true);
        setSwitch2(false);
    };

    const handleSwitch2 = () => {
        setSwitch1(false);
        setSwitch2(true);
    };

    const toggleEstado = () => {
        const nuevoEstado = !estadoBoton;
        socket.emit("cambiarEstado", { estado: nuevoEstado });

        Swal.fire({
            title: nuevoEstado ? "Encendido" : "Apagado",
            text: `El motor se ha ${nuevoEstado ? "encendido" : "apagado"}.`,
            icon: nuevoEstado ? "success" : "warning",
            confirmButtonText: "OK",
        });
    };

    return (
        <div className={styles.tarjetaControl}>
            <div className={styles.iconosControl}>
                <span><FaBroadcastTower className={styles.iconoControl} /></span>
                <span><FaBolt className={`${styles.iconoControl} ${styles.verdeControl}`} /></span>
                <span><FaIndustry className={styles.iconoControl} /></span>
                <span><FaBolt className={`${styles.iconoControl} ${styles.rojoControl}`} /></span>
                <span><TbCircleDashedLetterG className={styles.iconoControl} /></span>
            </div>

            <div className={styles.switchesControl}>
                <label className={styles.switchControl}>
                    <input type="checkbox" checked={switch1} onChange={handleSwitch1} disabled />
                    <span className={styles.sliderControl}></span>
                </label>

                <label className={styles.switchControl}>
                    <input type="checkbox" checked={switch2} onChange={handleSwitch2} disabled />
                    <span className={styles.sliderControl}></span>
                </label>
            </div>

            <p className={styles.textoControl}>Control Motor</p>

            <div className={styles.botonesControl}>
                <button 
                    className={styles.botonVerdeControl} 
                    onClick={toggleEstado} 
                    disabled={estadoBoton}
                >
                    I
                </button>
                <button 
                    className={styles.botonRojoControl} 
                    onClick={toggleEstado} 
                    disabled={!estadoBoton}
                >
                    O
                </button>
            </div>
        </div>
    );
};

export default Control;
