import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Style from '../assets/css/recaptcha.module.css';

const RecaptchaForm = () => {
    const [name, setName] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const recaptchaRef = useRef(null);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaValue) {
            setMessage('Por favor, completa el reCAPTCHA.');
            setShowModal(true);
            setResetForm(false);
            return;
        }

        try {
            const response = await fetch('https://recaptcha-hvmv.onrender.com/auth/validate-recaptcha', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, recaptchaToken: captchaValue })
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                setResetForm(true);
            } else {
                setMessage('Error en la validación.');
                setResetForm(true);
            }
        } catch (error) {
            setMessage('Error al conectarse con el servidor.');
            setResetForm(true);
        }

        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);

        if (resetForm) {
            setName('');
            setCaptchaValue(null);
            recaptchaRef.current?.reset();
        }
    };

    return (
        <section className={Style.recaptchaSection}>
            <form onSubmit={handleSubmit}>
                <label>
                    Nombre:
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </label>

                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lf2z-EqAAAAAHK_SHYkPR9eA8rsHieeSKM3TzFq"
                    onChange={handleCaptchaChange}
                    className={Style.recaptcha}
                />

                <button type="submit">Enviar</button>
            </form>

            {showModal && (
                <div className={Style.modalOverlay}>
                    <div className={Style.modal}>
                        <p>{message}</p>
                        <button onClick={handleCloseModal}>Cerrar</button>
                    </div>
                </div>
            )}
        </section>
    );
};

export default RecaptchaForm;
