import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Lockscreen from './components/lockscren';
import Landing from './components/landing';
import Error from './components/error';
import Detector from './components/detector';
import Receiver from './components/receiver';
import Sender from './components/sender';
import Estados from './components/estados';
import DosPasos from './components/dosPasos';
import Authenticator from './components/authenticator';
import World from './components/world';
import CountryPage from './components/countryPage';
import MyCountries from './components/myCountries';
import './App.css';
import RecaptchaForm from './components/recaptcha';
import Index from './components';
import Quotes from './components/quotes';
import MarkDown from './components/markdown';
import StudyBreak from './components/studyBreak';
import Drums from './components/drums';
import Sort from './components/sort';
import Glassmorphism from './components/glassmorphism';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Lockscreen />} />
        <Route path="/index" element={<Index />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/detect" element={<Detector />} />
        <Route path="/receiver" element={<Receiver />} />
        <Route path="/sender" element={<Sender />} />
        <Route path="/ejemplo" element={<Estados />} />
        <Route path="/dosPasos" element={<DosPasos />} />
        <Route path="/authenticator" element={<Authenticator />} />
        <Route path="/world" element={<World />} />
        <Route path="/world/:countryId" element={<CountryPage />} />
        <Route path="/world/mis-paises" element={<MyCountries />} />
        <Route path="/recaptcha" element={<RecaptchaForm />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/markdown" element={<MarkDown />} />
        <Route path="/study-break" element={<StudyBreak />} />
        <Route path="/drums" element={<Drums />} />
        <Route path="/sort" element={<Sort />} />
        <Route path="/glassmorphism" element={<Glassmorphism />} />
        <Route
          path="*"
          element={
            <>
              <Error />
              <Navigate to="/not-found" replace />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;