import { useState } from "react";
import Control from "./control";
import styles from "../assets/css/estados.module.css";

const Estados = () => {
    const [activo, setActivo] = useState("Auto");

    return (
        <section className={styles.seccionEjemplo}>
            <div className={styles.containerEstados}>
                {["Off", "Man", "Auto", "Test"].map((modo) => (
                    <button
                        key={modo}
                        className={`${styles.botonEstados} ${activo === modo ? styles.activoEstados : ""}`}
                        onClick={() => setActivo(modo)}
                    >
                        {modo}
                    </button>
                ))}
            </div>
            <Control />
        </section>
    );
};

export default Estados;
