import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { uploadBytesResumable } from 'firebase/storage';
import { CSS } from '@dnd-kit/utilities';
import Style from '../assets/css/myCountries.module.css';

const SortableImage = ({ url, id, onDelete }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} className={Style.imageWrapper}>
            <img
                {...attributes}
                {...listeners}
                src={url}
                alt="Imagen"
                className={Style.collageImage}
                style={style}
            />
            <button className={Style.deleteButton} onClick={() => onDelete(url)}>Eliminar</button>
        </div>
    );
};

const MyCountries = () => {
    const [visitedCountries, setVisitedCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [imageGallery, setImageGallery] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [showGallery, setShowGallery] = useState(false);
    const navigate = useNavigate();
    const auth = getAuth();
    const storage = getStorage();
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            fetchVisitedCountries(user.uid);
        }
    }, [auth]);

    const fetchVisitedCountries = async (userId) => {
        const db = getDatabase();
        const userRef = ref(db, `usuarios/${userId}/paises`);

        try {
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                const countries = Object.values(snapshot.val());
                setVisitedCountries(countries);
            }
        } catch (error) {
            console.error("Error obteniendo países visitados:", error);
        }
    };

    const handleCountryClick = (country) => {
        setSelectedCountry(country);
        setImageGallery([]);
        setShowGallery(false);
    };

    const handleViewImages = async (country) => {
        const user = auth.currentUser;
        if (!user) return;

        const imagesRef = storageRef(storage, `imagenes/${user.uid}/${country.nombre}/`);
        try {
            const result = await listAll(imagesRef);
            const urls = await Promise.all(result.items.map(item => getDownloadURL(item)));
            setImageGallery(urls);
            setShowGallery(true);
        } catch (error) {
            console.error("Error obteniendo imágenes:", error);
        }
    };

    const handleImageUpload = async (event) => {
        const user = auth.currentUser;
        if (!user || !selectedCountry) return;
    
        const files = event.target.files;
        setUploading(true);
        setUploadProgress(0);
    
        const totalFiles = files.length;
        let uploadedFiles = 0;
    
        const uploadPromises = Array.from(files).map((file) => {
            return new Promise((resolve, reject) => {
                const fileRef = storageRef(storage, `imagenes/${user.uid}/${selectedCountry.nombre}/${file.name}`);
                const uploadTask = uploadBytesResumable(fileRef, file);
    
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(Math.round(progress));
                    },
                    (error) => reject(error),
                    async () => {
                        const url = await getDownloadURL(uploadTask.snapshot.ref);
                        uploadedFiles += 1;
                        setUploadProgress(Math.round((uploadedFiles / totalFiles) * 100));
                        resolve(url);
                    }
                );
            });
        });
    
        const urls = await Promise.all(uploadPromises);
        setImageGallery((prev) => [...prev, ...urls]);
    
        setUploading(false);
        setUploadProgress(0);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setImageGallery((prev) => {
                const oldIndex = prev.indexOf(active.id);
                const newIndex = prev.indexOf(over.id);
                return arrayMove(prev, oldIndex, newIndex);
            });
        }
    };

    const handleDeleteImage = async (url) => {
        const user = auth.currentUser;
        if (!user) return;

        const fileName = url.split('/').pop();
        const fileRef = storageRef(storage, `imagenes/${user.uid}/${selectedCountry.nombre}/${fileName}`);

        try {
            await storageRef(storage, fileRef).delete();
            setImageGallery(prev => prev.filter(imageUrl => imageUrl !== url));
        } catch (error) {
            console.error("Error al eliminar la imagen:", error);
        }
    };

    return (
        <section className={Style.myCountries}>
            <button onClick={() => navigate(-1)} className={Style.backButton}>Regresar</button>
            <h1>Países visitados</h1>
            {visitedCountries.length > 0 ? (
                <ul>
                    {visitedCountries.map((country, index) => (
                        <div key={index} className={Style.countryItemContainer}>
                            <li onClick={() => handleCountryClick(country)} className={Style.countryItem}>
                                <span className={Style.countryName}>
                                    {country.nombre}
                                </span>
                            </li>
                            <button onClick={() => handleViewImages(country)} className={Style.viewButton}>
                                Ver imágenes
                            </button>
                        </div>
                    ))}
                </ul>
            ) : (
                <p>No has registrado países visitados aún.</p>
            )}

            {selectedCountry && (
                <div className={Style.modal}>
                    <div className={Style.modalContent}>
                        <h2>{selectedCountry.nombre}</h2>
                        <input type="file" multiple onChange={handleImageUpload} />
                        {uploading && (
                            <p>Subiendo imágenes... {uploadProgress}%</p>
                        )}
                        <button className={Style.closeGalleryButton} onClick={() => setSelectedCountry(null)}>Cerrar</button>
                    </div>
                </div>
            )}

            {showGallery && (
                <div className={Style.gallerySection}>
                    <button onClick={() => setShowGallery(false)} className={Style.closeGalleryButton}>
                        Cerrar Galería
                    </button>
                    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={imageGallery} strategy={verticalListSortingStrategy}>
                            <div className={Style.collage}>
                                {imageGallery.length > 0 ? (
                                    imageGallery.map((url) => (
                                        <SortableImage
                                            key={url}
                                            id={url}
                                            url={url}
                                            onDelete={handleDeleteImage}
                                        />
                                    ))
                                ) : (
                                    <p>No hay imágenes disponibles.</p>
                                )}
                            </div>
                        </SortableContext>
                    </DndContext>
                </div>
            )}
        </section>
    );
};

export default MyCountries;
