import React, { useState } from 'react';
import Style from '../assets/css/detector.module.css';

const Detector = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [resultImage, setResultImage] = useState(null);

    // Manejar la selección de la imagen
    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    // Manejar el envío del formulario para procesar la imagen
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedImage) {
            alert("Por favor selecciona una imagen primero.");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedImage);

        try {
            // Cambiar la URL aquí según si estás en desarrollo o producción
            const response = await fetch("http://localhost:8000/detect/", {  // URL en desarrollo
                method: "POST",
                body: formData,
            });
            
            /*const response = await fetch("https://www.omar-cruz.com/detect/", { // URL en producción
                method: "POST",
                body: formData,
            });*/

            if (!response.ok) {
                throw new Error("Error en la detección de objetos");
            }

            // Obtener la imagen procesada de vuelta
            const blob = await response.blob();
            setResultImage(URL.createObjectURL(blob));
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <section className={Style.detectorComponent}>
            <h1 className={Style.detectorTitle}>Computer, Glasses, Headphone, Person and QR Codes detector</h1>

            <form onSubmit={handleSubmit} className={Style.form}>
                <div className={Style.inputContainer}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className={Style.inputFile}
                    />
                </div>
                <div className={Style.buttonContainer}>
                    <button type="submit" className={Style.submitButton}>
                        Detectar
                    </button>
                </div>
            </form>

            {resultImage && (
                <div className={Style.resultContainer}>
                    <img src={resultImage} alt="Resultado de detección" className={Style.resultImage} />
                </div>
            )}
        </section>
    );
}

export default Detector;
