import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, get, remove } from 'firebase/database';
import Style from '../assets/css/countryPage.module.css';
import '../../firebase';

const CountryPage = () => {
    const { countryId } = useParams();
    const navigate = useNavigate();
    const [countryData, setCountryData] = useState(null);
    const [user, setUser] = useState(null);
    const [visited, setVisited] = useState(false);

    useEffect(() => {
        fetch(`https://restcountries.com/v3.1/alpha/${countryId}`)
            .then(response => response.json())
            .then(data => setCountryData(data[0]))
            .catch(error => console.error("Error fetching country data:", error));
    }, [countryId]);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                checkIfVisited(currentUser.uid);
            }
        });
        return () => unsubscribe();
    }, [countryId]);

    const checkIfVisited = async (userId) => {
        const db = getDatabase();
        const userRef = ref(db, `usuarios/${userId}/paises/${countryId}`);

        try {
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                setVisited(true);
            } else {
                setVisited(false);
            }
        } catch (error) {
            console.error("Error verificando país visitado:", error);
        }
    };

    const toggleVisited = async () => {
        if (!user) {
            alert("Debes iniciar sesión para gestionar los países visitados.");
            return;
        }

        const db = getDatabase();
        const userRef = ref(db, `usuarios/${user.uid}/paises/${countryId}`);

        try {
            if (visited) {
                await remove(userRef);
                setVisited(false);
            } else {
                await set(userRef, {
                    nombre: countryData.name.common,
                    fecha: new Date().toISOString(),
                });
                setVisited(true);
            }
        } catch (error) {
            console.error("Error al actualizar el estado de visita:", error);
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <section className={Style.countryPage}>
            <button onClick={handleGoBack} className={Style.backButton}>Regresar</button>

            {countryData ? (
                <>
                    <h1>{countryData.name.common} ({countryData.cca2})</h1>
                    <img src={countryData.flags.svg} alt={`Bandera de ${countryData.name.common}`} className={Style.flag} />
                    <p><strong>Capital:</strong> {countryData.capital?.[0]}</p>
                    <p><strong>Continente:</strong> {countryData.region}</p>
                    <p><strong>Población:</strong> {countryData.population.toLocaleString()}</p>
                    <p><strong>Idioma(s):</strong> {Object.values(countryData.languages).join(', ')}</p>
                    <p><strong>Moneda:</strong> {Object.values(countryData.currencies)[0].name} ({Object.values(countryData.currencies)[0].symbol})</p>
                    <a href={`https://www.google.com/maps?q=${countryData.latlng[0]},${countryData.latlng[1]}`} target="_blank" rel="noopener noreferrer">
                        Ver en Google Maps
                    </a>

                    {user && (
                        <button 
                            onClick={toggleVisited} 
                            className={`${Style.visitButton} ${visited ? Style.visited : ''}`}
                        >
                            {visited ? "Eliminar de visitados" : "Ya lo visité"}
                        </button>
                    )}
                </>
            ) : (
                <p>Cargando información del país...</p>
            )}
        </section>
    );
};

export default CountryPage;
