import React, { useEffect, useRef } from 'react';
import Style from '../assets/css/projects.module.css';

function Projects() {
    const folderRef = useRef(null);

    const projects = [
        { name: 'Trabajos', url:'https://www.omar-cruz.com/index', imgSrc: 'document.webp' },
        { name: 'Esqueleto de página web', url: 'https://esqueleto-three.vercel.app', imgSrc: 'document.webp' },
        { name: 'Andivii Nundoo', url: 'https://nasa-lemon.vercel.app/', imgSrc: 'document.webp' },
        { name: 'CSS helper', url: 'https://squirrel-css-helper.vercel.app/', imgSrc: 'document.webp' },
        { name: 'QR Generator', url: 'https://qr-generator-and-url-shortener.vercel.app/', imgSrc: 'document.webp' },
        { name: 'UTeMitas', url: 'https://utemitas-asesorias.vercel.app/', imgSrc: 'document.webp' },
        { name: 'Expense Tracker', url: 'https://squirrel-expense-tracker.vercel.app', imgSrc: 'document.webp' },
        { name: 'Trello', url: 'https://squirrel-trello.vercel.app/', imgSrc: 'document.webp' },
        { name: 'Ecommerce', url: 'https://ixvanna.vercel.app/', imgSrc: 'document.webp' },
    ];

    const handleClickOutside = (event) => {
        if (folderRef.current && !folderRef.current.contains(event.target)) {
            setIsSelected(false);
        }
    };

    // Detecta si es un dispositivo móvil
    const isMobile = () => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    };

    // Maneja el evento touchend para abrir el enlace en móviles
    const handleTouchEnd = (url) => {
        if (isMobile()) {
            window.open(url, '_blank');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className={Style.projectsContainer}>
                <div className={Style.projectsRow}>
                    {projects.map((project, index) => (
                        <div
                            className={Style.project}
                            key={index}
                            onTouchEnd={() => handleTouchEnd(project.url)}
                        >
                            <a href={project.url} target="_blank" rel="noreferrer">
                                <img src={project.imgSrc} alt={project.name} />
                                <span>{project.name}</span>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Projects;
