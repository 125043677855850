import React from 'react';
import { Link } from 'react-router-dom';
import Style from '../assets/css/index.module.css';

const Index = () => {
    return (
        <section className={Style.indexSection}>
            <h1>Index</h1>
            <nav>
                <ul>
                    <li><Link to="/">Lockscreen</Link></li>
                    <li><Link to="/landing">Landing</Link></li>
                    {/* <li><Link to="/detect">Detector</Link></li> */}
                    <li><Link to="/receiver">Receiver</Link></li>
                    <li><Link to="/sender">Sender</Link></li>
                    <li><Link to="/ejemplo">Estados</Link></li>
                    <li><Link to="/dosPasos">Dos Pasos</Link></li>
                    <li><Link to="/authenticator">Authenticator</Link></li>
                    <li><Link to="/world">World</Link></li>
                    {/* <li><Link to="/world/mis-paises">Mis Países</Link></li> */}
                    <li><Link to="/recaptcha">Recaptcha</Link></li>
                    <li><Link to="/quotes">Quotes</Link></li>
                    <li><Link to="/markdown">MarkDown</Link></li>
                    <li><Link to="/study-break">Study-Break</Link></li>
                    <li><Link to="/drums">Drums</Link></li>
                    <li><Link to="/sort">Sort</Link></li>
                    <li><Link to="/glassmorphism">Glassmorphism</Link></li>
                </ul>
            </nav>
        </section>
    );
}

export default Index;
