import React, { useState, useEffect } from 'react';
import Style from '../assets/css/studybreak.module.css';

const StudyBreak = () => {
    const [breakLength, setBreakLength] = useState(5);
    const [sessionLength, setSessionLength] = useState(25);
    const [timeLeft, setTimeLeft] = useState(25 * 60);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isSession, setIsSession] = useState(true);
    const [audio] = useState(new Audio('/alarm.mp3'));

    useEffect(() => {
        let timer;
        if (isTimerRunning) {
            timer = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 0) {
                        audio.play();
                        // Switch between session and break
                        setIsSession(prevState => !prevState);
                        // Reset the time to the appropriate length
                        return isSession ? breakLength * 60 : sessionLength * 60;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isTimerRunning, isSession, breakLength, sessionLength, audio]);

    // Watch for changes in sessionLength and update the timeLeft if it's during a session
    useEffect(() => {
        if (isSession) {
            setTimeLeft(sessionLength * 60);
        }
    }, [sessionLength, isSession]);

    const reset = () => {
        setIsTimerRunning(false);
        setIsSession(true);
        setTimeLeft(sessionLength * 60);
        setBreakLength(5);
        setSessionLength(25);
        audio.pause();
        audio.currentTime = 0;
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <div className={Style.studyBreak}>
            {/* Break Controls */}
            <div className={Style.control}>
                <div className={Style.label}>Break Length</div>
                <div className={Style.controls}>
                    <button
                        className={Style.decrement}
                        onClick={() => setBreakLength(Math.max(breakLength - 1, 1))}
                    >
                        -
                    </button>
                    <span className={Style.breakLength}>{breakLength}</span>
                    <button
                        className={Style.increment}
                        onClick={() => setBreakLength(Math.min(breakLength + 1, 60))}
                    >
                        +
                    </button>
                </div>
            </div>

            {/* Session Controls */}
            <div className={Style.control}>
                <div className={Style.label}>Session Length</div>
                <div className={Style.controls}>
                    <button
                        className={Style.decrement}
                        onClick={() => setSessionLength(Math.max(sessionLength - 1, 1))}
                    >
                        -
                    </button>
                    <span className={Style.sessionLength}>{sessionLength}</span>
                    <button
                        className={Style.increment}
                        onClick={() => setSessionLength(Math.min(sessionLength + 1, 60))}
                    >
                        +
                    </button>
                </div>
            </div>

            <div className={Style.timerLabel}>{isSession ? "Session" : "Break"}</div>
            <div className={Style.timeLeft}>{formatTime(timeLeft)}</div>

            <button
                className={Style.startStop}
                onClick={() => setIsTimerRunning(prevState => !prevState)}
            >
                {isTimerRunning ? "Pause" : "Start"}
            </button>

            <button className={Style.reset} onClick={reset}>Reset</button>

            <audio id="beep" src="https://www.soundjay.com/button/beep-07.wav" />
        </div>
    );
};

export default StudyBreak;
