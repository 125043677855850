import { useState } from "react";
import { marked } from "marked";
import styles from "../assets/css/markdown.module.css";

const defaultMarkdown = `# Encabezado H1
## Encabezado H2
[Mi portfolio](https://www.omar-cruz.com)

\`Código en línea\`
\`\`\`
Bloque de código

console.log("Hola mundo");
\`\`\`
- Elemento de lista
> Esto es una cita
![Imagen de ejemplo](https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFUAfyVe3Easiycyh3isP9wDQTYuSmGPsPQvLIJdEYvQ_DsFq5Ez2Nh_QjiS3oZ3B8ZPfK9cZQyIStmQMV1lDPLw)
**Texto en negrita**`;

const MarkDown = () => {
    const [text, setText] = useState(defaultMarkdown);

    // Función para descargar el archivo Markdown
    const handleDownload = () => {
        const blob = new Blob([text], { type: "text/markdown" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "documento.md";
        link.click();
    };

    return (
        <div className={styles.containerMarkdown}>
            <h1 className={styles.title}>Editor Markdown</h1>

            <div className={styles.flexContainer}>
                {/* Área de edición */}
                <div className={styles.editorContainer}>
                    <label htmlFor="editor" className={styles.label}>
                        Escribe tu Markdown aquí:
                    </label>
                    <textarea
                        id="editor"
                        className={styles.textarea}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                </div>

                {/* Vista previa */}
                <div className={styles.previewContainer}>
                    <label className={styles.label}>
                        Vista previa:
                    </label>
                    <div
                        id="preview"
                        className={styles.preview}
                        dangerouslySetInnerHTML={{ __html: marked(text, { breaks: true }) }}
                    />
                </div>
            </div>

            {/* Botón de descarga */}
            <div className={styles.buttonContainer}>
                <button
                    onClick={handleDownload}
                    className={styles.button}
                >
                    Descargar Markdown
                </button>
            </div>
        </div>
    );
};

export default MarkDown;