import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import Style from '../assets/css/sender.module.css';

const socket = io('https://nest-api-example-1.onrender.com');

const Sender = () => {
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [deliveryStatus, setDeliveryStatus] = useState('');

    // Handle connection and disconnection
    useEffect(() => {
        socket.on('connect', () => {
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            setIsConnected(false);
        });

        // Listen for delivery confirmation
        socket.on('messageDelivered', (data) => {
            setDeliveryStatus(data.message);
        });

        // Cleanup on unmount
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('messageDelivered');
            socket.disconnect();
        };
    }, []);

    const handleSendMessage = () => {
        if (message.trim()) {
            socket.emit('sendMessage', message);
            setMessage(''); 
            setDeliveryStatus('Sending message...');
        }
    };

    return (
        <section className={Style.senderSection}>
            <h1 className={Style.senderTitle}>Sender</h1>
            <p className={Style.senderStatus}>Connection status: {isConnected ? 'Connected' : 'Disconnected'}</p>
            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter a message"
                className={Style.senderInput}
            />
            <button onClick={handleSendMessage} className={Style.senderButton}>
                Send Message
            </button>
            {response && <p className={Style.senderResponse}>Response: {response}</p>}
            {deliveryStatus && <p className={Style.senderResponse}>Delivery Status: {deliveryStatus}</p>}
        </section>
    );
};

export default Sender;