import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import Style from '../assets/css/receiver.module.css';

const socket = io('https://nest-api-example-1.onrender.com');

const Receiver = () => {
    const [message, setMessage] = useState('');
    const [isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        socket.on('connect', () => {
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            setIsConnected(false);
        });

        socket.on('receiveMessage', (message) => {
            setMessage(message);
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('receiveMessage');
            socket.disconnect();
        };
    }, []);

    return (
        <section className={Style.receiverSection}>
            <h1 className={Style.receiverTitle}>Receiver</h1>
            <p className={Style.receiverStatus}>Connection status: {isConnected ? 'Connected' : 'Disconnected'}</p>
            <p className={Style.receiverText}>Message: {message}</p>
        </section>
    );
};

export default Receiver;