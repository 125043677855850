import { useState, useEffect } from "react";
import { FaTwitter, FaTumblr } from "react-icons/fa";
import Style from "../assets/css/quotes.module.css";

const Quotes = () => {
    const [quote, setQuote] = useState("");
    const [author, setAuthor] = useState("");
    const [color, setColor] = useState("#E74C3C");

    const fetchQuote = async () => {
        try {
            const response = await fetch("https://api.quotable.io/quotes/random");
            const data = await response.json();
            setQuote(data[0].content);
            setAuthor(data[0].author);
            setColor(getRandomColor());
        } catch (error) {
            console.error("Error fetching quote:", error);
        }
    };
    

    useEffect(() => {
        fetchQuote();
    }, []);

    const getRandomColor = () => {
        const colors = ["#E74C3C", "#3498DB", "#2ECC71", "#9B59B6", "#F39C12"];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    return (
        <div className={Style.quoteContainer} style={{ backgroundColor: color }}>
            <div id="quote-box" className={Style.quoteBox}>
                <p id="text" className={Style.text} style={{ color: color }}>
                    <span className={Style.quoteIcon}>❝</span> {quote}
                </p>
                <p id="author" className={Style.author} style={{ color: color }}>
                    - {author}
                </p>
                <div className={Style.quoteButtons}>
                    <div className={Style.socialButtons}>
                        <a
                            id="tweet-quote"
                            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                                `"${quote}" - ${author}`
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ backgroundColor: color }}
                        >
                            <FaTwitter />
                        </a>
                        <a
                            href="https://www.tumblr.com/share"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ backgroundColor: color }}
                        >
                            <FaTumblr />
                        </a>
                    </div>
                    <button
                        id="new-quote"
                        className={Style.newQuote}
                        onClick={fetchQuote}
                        style={{ backgroundColor: color }}
                    >
                        Nueva Cita
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Quotes;
