import React, { useState } from 'react';
import Style from '../assets/css/dosPasos.module.css';
import Swal from 'sweetalert2';

const DosPasos = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [verified, setVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setError('Por favor, ingresa un correo electrónico válido.');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            const response = await fetch('https://dospasos.onrender.com/api/send-verification-code', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setStep(2);
                setEmailSent(true);
                Swal.fire({
                    icon: 'success',
                    title: 'Código enviado',
                    text: 'Se ha enviado un código de verificación a tu correo electrónico.',
                });
            } else {
                const data = await response.json();
                setError(data.message || 'Error al enviar el código de verificación');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message || 'No se pudo enviar el código de verificación. Inténtalo de nuevo.',
                });
            }
        } catch (err) {
            setError('Error de conexión');
            Swal.fire({
                icon: 'error',
                title: 'Error de conexión',
                text: 'Hubo un problema al conectarse al servidor. Inténtalo de nuevo.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        if (!code) {
            setError('Por favor, ingresa el código de verificación.');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            const response = await fetch('https://dospasos.onrender.com/api/verify-code', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, code }),
            });

            const data = await response.json();

            if (response.ok) {
                setVerified(true);
                Swal.fire({
                    icon: 'success',
                    title: 'Código verificado',
                    text: data.message || 'El código de verificación es correcto.',
                });
            } else {
                setError(data.message || 'Código de verificación incorrecto');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message || 'El código de verificación es incorrecto. Inténtalo de nuevo.',
                });
            }
        } catch (err) {
            setError('Error de conexión');
            Swal.fire({
                icon: 'error',
                title: 'Error de conexión',
                text: 'Hubo un problema al conectarse al servidor. Inténtalo de nuevo.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const resetVerification = () => {
        setStep(1);
        setEmail('');
        setCode('');
        setError('');
        setEmailSent(false);
        setVerified(false);
    };

    return (
        <section className={Style.dosPasosContainer}>
            <h1 className={Style.tituloDosPasos}>Verificación de dos pasos</h1>
            {verified ? (
                <div className={Style.verificadoContainer}>
                    <h2 className={Style.verificadoMensaje}>✅ Verificación exitosa</h2>
                    <button className={Style.botonDosPasos} onClick={resetVerification}>
                        Probar de nuevo
                    </button>
                </div>
            ) : step === 1 ? (
                <form onSubmit={handleEmailSubmit}>
                    <h2 className={Style.pasoUno}>Paso 1: Ingresa tu correo electrónico</h2>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className={Style.inputDosPasos}
                        placeholder="Correo electrónico"
                        disabled={isLoading}
                    />
                    <button className={Style.botonDosPasos} type="submit" disabled={isLoading}>
                        {isLoading ? 'Enviando...' : 'Enviar código'}
                    </button>
                    {error && <p className={Style.dosPasosError}>{error}</p>}
                </form>
            ) : (
                <form onSubmit={handleCodeSubmit}>
                    <h2 className={Style.pasoDos}>Paso 2: Ingresa el código de verificación</h2>
                    <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required
                        className={Style.inputDosPasos}
                        placeholder="Código de verificación"
                        disabled={isLoading}
                    />
                    <button className={Style.botonDosPasos} type="submit" disabled={isLoading}>
                        {isLoading ? 'Verificando...' : 'Verificar código'}
                    </button>
                    {error && <p className={Style.dosPasosError}>{error}</p>}
                    {emailSent && <p className={Style.dosPasosSuccess}>Se ha enviado un código a tu correo.</p>}
                </form>
            )}
        </section>
    );
};

export default DosPasos;