import React, { useState, useRef, useEffect } from 'react';
import Style from '../assets/css/drums.module.css';

const Drums = () => {
    const [display, setDisplay] = useState("");

    const soundRefs = {
        Q: useRef(null),
        W: useRef(null),
        E: useRef(null),
        A: useRef(null),
        S: useRef(null),
        D: useRef(null),
        Z: useRef(null),
        X: useRef(null),
        C: useRef(null)
    };

    const sounds = [
        { id: "Q", sound: "Heater 1", src: "/sounds/Heater-1.mp3" },
        { id: "W", sound: "Heater 2", src: "/sounds/Heater-2.mp3" },
        { id: "E", sound: "Heater 3", src: "/sounds/Heater-3.mp3" },
        { id: "A", sound: "Heater 4", src: "/sounds/Heater-4_1.mp3" },
        { id: "S", sound: "Cev", src: "/sounds/Cev_H2.mp3" },
        { id: "D", sound: "Dsc Oh", src: "/sounds/Dsc_Oh.mp3" },
        { id: "Z", sound: "Kick-n'-Hat", src: "/sounds/KickHat.mp3" },
        { id: "X", sound: "Kick", src: "/sounds/RP4_KICK_1.mp3" },
        { id: "C", sound: "Heater 6", src: "/sounds/Heater-6.mp3" }
    ];

    const playSound = (id, sound) => {
        setDisplay(sound);
        const audio = soundRefs[id].current;
        if (audio) {
            audio.load();
            audio.play().catch(error => {
                console.error("Audio play error:", error);
            });
        }
    };    

    const handleKeyPress = (event) => {
        const key = event.key.toUpperCase();
        const sound = sounds.find(s => s.id === key);
        if (sound) {
            playSound(sound.id, sound.sound);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div id="drum-machine" className={Style.drumMachine}>
            <div id="display" className={Style.display}>{display}</div>
            <div className={Style.drumPads}>
                {sounds.map((sound) => (
                    <div
                        key={sound.id}
                        className="drum-pad"
                        id={sound.id}
                        onClick={() => playSound(sound.id, sound.sound)}
                    >
                        {sound.id}
                        <audio ref={soundRefs[sound.id]} className="clip" id={sound.id} src={sound.src} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Drums;
