import React, { useState } from 'react';
import axios from 'axios';
import Style from '../assets/css/authenticator.module.css';

const Authenticator = () => {
    const [qrCode, setQrCode] = useState(null);
    const [token, setToken] = useState('');
    const [isValid, setIsValid] = useState(null);
    const userId = 'UsuarioPrueba1';

    // Generar código QR
    const generateQRCode = async () => {
        try {
            const { data } = await axios.get(`https://authenticator-5cdq.onrender.com/auth/2fa/generate/${userId}`);
            setQrCode(data.qrCodeDataURL);
        } catch (error) {
            console.error('Error generando 2FA:', error);
        }
    };

    // Verificar código 2FA
    const verifyToken = async () => {
        try {
            const { data } = await axios.post(`https://authenticator-5cdq.onrender.com/auth/2fa/verify/${userId}`, { token });
            setIsValid(data.valid);
        } catch (error) {
            console.error('Error verificando 2FA:', error);
        }
    };

    return (
        <section className={Style.authenticator}>
            <h2 className={Style.tituloAuthenticator}>Autenticación en dos pasos</h2>

            {!qrCode ? (
                <button onClick={generateQRCode}>Generar QR</button>
            ) : (
                <>
                    <img src={qrCode} alt="QR Code" />
                    <p>Escanea el código en la App Authenticator o Authenticator +</p>
                </>
            )}

            <input
                type="text"
                placeholder="Código de 6 dígitos"
                value={token}
                onChange={(e) => setToken(e.target.value)}
            />
            <button onClick={verifyToken}>Verificar Código</button>

            {isValid !== null && (
                <p>{isValid ? '✅ Código válido' : '❌ Código incorrecto'}</p>
            )}
        </section>
    );
};

export default Authenticator;
